
import { defineComponent, onMounted, ref } from "vue";

import fetchData from "@/utils/axios";

//Blocks
import Jumbortron from "@/components/template/blocks/Jumbortron.vue";
import Paragraph from "@/components/template/blocks/Paragraph.vue";
import Contact from "@/components/template/blocks/form/Contact.vue";
import List from "@/components/template/blocks/List.vue";

export default defineComponent({
  components: { Jumbortron, Paragraph, Contact, List },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    const data = ref(null);
    const error = ref(null);

    onMounted(async () => {
      try {
        const res = await fetchData("/api/pages", {
          filters: {
            Title: {
              $eq: "Fonctionnalités",
            },
          },
          populate: {
            Hero: {
              populate: "*",
            },
            Blocks: {
              populate: {
                Img: { populate: "*" },
                ListItem: { populate: "*" },
              },
            },
          },
        });

        data.value = res.data.data[0].attributes;
      } catch (err) {
        error.value = err;
      }
    });

    return { backendUrl, data, error };
  },
});
