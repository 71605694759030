
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

//Layouts
import Container from "@/components/layouts/Container.vue";

//Molecules
import List from "@/components/molecules/List.vue";

export default defineComponent({
  components: { Container, List },
  props: {
    block: {
      type: Object,
    },
    fit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;

    const bg = ref<HTMLDivElement | null>(null);
    const obj = ref<HTMLDivElement | null>(null);

    const scroll = () => {
      const scrollToTop =
        (bg.value?.getBoundingClientRect().top ?? 0) -
        (window.innerHeight + 100);

      if (obj.value) {
        obj.value.style.transform = `translateY(${
          -(scrollToTop + 600) / 10
        }px)`;
      }
    };

    onMounted(() => {
      const scrollToTop =
        (bg.value?.getBoundingClientRect().top ?? 0) -
        (window.innerHeight + 100);

      if (scrollToTop < 0 && obj.value) {
        obj.value.style.transform = `translateY(${-(scrollToTop + 600) / 5}px)`;
      }

      window.addEventListener("scroll", scroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", scroll);
    });

    return { backendUrl, bg, obj };
  },
});
