
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    color: {
      type: String as PropType<"red" | "violet" | "green">,
      validator: (v: string) => ["red", "violet", "green"].indexOf(v) !== -1,
    },
  },
  setup() {
    return {};
  },
});
