
import { defineComponent, PropType } from "vue";

//Layouts
import Container from "../layouts/Container.vue";

//Atoms
import ListItem from "@/components/atoms/listitem/ListItem.vue";
import Title from "@/components/atoms/Title.vue";

export default defineComponent({
  components: { ListItem, Title, Container },
  props: {
    value: {
      type: Array,
    },
    color: {
      type: String as PropType<"red" | "violet" | "green">,
      validator: (v: string) => ["red", "violet", "green"].indexOf(v) !== -1,
    },
    bgText: {
      type: String,
    },
    fit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
