import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4be4ce1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "bg",
  class: "wrapper"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_List = _resolveComponent("List", true)!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, { size: "medium" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_List, {
          value: _ctx.block?.ListItem,
          color: _ctx.block?.Color,
          bgText: _ctx.block?.BackgroundText,
          fit: _ctx.fit
        }, null, 8, ["value", "color", "bgText", "fit"]),
        (_ctx.block?.Img?.data)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`img-wrapper ${_ctx.block?.ImgReverse && 'reverse'}`)
            }, [
              _createElementVNode("img", {
                ref: "obj",
                class: "img",
                src: _ctx.backendUrl + _ctx.block?.Img?.data.attributes.url,
                alt: ""
              }, null, 8, _hoisted_2)
            ], 2))
          : _createCommentVNode("", true)
      ], 512)
    ]),
    _: 1
  }))
}