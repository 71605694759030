import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-516f9a62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      value: _ctx.bgText,
      tag: "h2",
      size: _ctx.fit ? 'large' : 'xlarge',
      color: _ctx.color
    }, null, 8, ["value", "size", "color"]),
    _createVNode(_component_Container, { size: "small" }, {
      default: _withCtx(() => [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (item, index) => {
            return (_openBlock(), _createBlock(_component_ListItem, {
              key: index,
              text: item.Text,
              color: _ctx.color
            }, null, 8, ["text", "color"]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}