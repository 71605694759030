
import { defineComponent, PropType } from "vue";

//Atoms
import Text from "../Text.vue";
import Bullet from "./Bullet.vue";

export default defineComponent({
  components: { Bullet, Text },
  props: {
    text: {
      type: String,
    },
    color: {
      type: String as PropType<"red" | "violet" | "green">,
      validator: (v: string) => ["red", "violet", "green"].indexOf(v) !== -1,
    },
  },
  setup() {
    return {};
  },
});
