import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-707bcdfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bullet = _resolveComponent("Bullet")!
  const _component_Text = _resolveComponent("Text")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    false
      ? (_openBlock(), _createBlock(_component_Bullet, {
          key: 0,
          color: _ctx.color
        }, null, 8, ["color"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Text, {
      value: _ctx.text,
      size: "medium"
    }, null, 8, ["value"]),
    _createElementVNode("div", {
      class: _normalizeClass(["separator", _ctx.color])
    }, null, 2)
  ]))
}